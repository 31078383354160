import { createSelector } from '@reduxjs/toolkit';

import { ScreenType } from 'enums/ui';

import temporaryApplyAPI from 'services/temporaryApplyAPI';
import { UiSettingsRealtorsFields } from 'services/temporaryApplyAPI/types';
import { RootState } from 'store/index';

export const selectScreenType = (state: RootState) => state.ui.screenType;

export const selectIsDesktopView = (state: RootState) => {
  const screenType = selectScreenType(state);
  return screenType === ScreenType.Desktop;
};

export const selectIsMobileView = (state: RootState) => {
  const screenType = selectScreenType(state);
  return screenType === ScreenType.Mobile;
};

export const selectIsMobileLandscapeAndLower = (state: RootState) => {
  const screenType = selectScreenType(state);
  return screenType === ScreenType.Mobile || screenType === ScreenType.MobileLandscape;
};

export const selectIsTabletAndHigher = (state: RootState) => {
  const screenType = selectScreenType(state);
  return screenType === ScreenType.Desktop || screenType === ScreenType.Tablet;
};

export const selectUiSettings = (state: RootState) =>
  temporaryApplyAPI.endpoints.getApplyUiSettings.select()(state).data?.applyFormUiSetting?.settings;

export const selectThemePrimaryColor = createSelector(
  [selectUiSettings],
  settings => (settings as UiSettingsRealtorsFields | undefined)?.common.primary.main,
);

export const selectRealtorsLogo = createSelector(
  [selectUiSettings],
  settings => (settings as UiSettingsRealtorsFields | undefined)?.common.realtorsLogoUrl,
);

export const selectRealtorsFavicon = createSelector(
  [selectUiSettings],
  settings => (settings as UiSettingsRealtorsFields | undefined)?.common.realtorsFavicon,
);
