import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';
import isEmpty from 'lodash/isEmpty';
import { useTheme } from 'styled-components';

import { ScreenType, TypographyComponent, TypographyWeight } from 'enums/ui';
import { getPDFLoanOptions } from 'utils/loanOptions';

import { useDownloadLoanOptionsMutation } from 'services/baseAPI';
import { selectIsMobileLandscapeAndLower, selectScreenType } from 'store/selectors/ui';
import {
  LoanOption,
  PotentialPurchaseLoanInfo,
  PotentialRefinanceLoanInfo,
} from 'types/application';

import ProgramCard from './components/ProgramCard';
import SendLoanProgramModal from 'components/@modals/SendLoanProgramsModal';
import Button from 'components/Button';
import CircleSpinner from 'components/CircleSpinner';
import { Typography } from 'components/Typography';

import {
  ButtonsContainer,
  DownloadPDFButton,
  DownloadPDFIcon,
  EmptyStateText,
  RateComparisonCarousel,
  RateComparisonTitle,
} from './RateComparison.styles';

interface RateComparisonProps {
  loanOptions: LoanOption[];
  loanInfo: PotentialPurchaseLoanInfo | PotentialRefinanceLoanInfo;
}

const RateComparison: FC<RateComparisonProps> = ({ loanOptions, loanInfo }) => {
  const theme = useTheme();

  const screenType = useSelector(selectScreenType);
  const isMobileView = useSelector(selectIsMobileLandscapeAndLower);

  const isShowHint = isMobileView && loanOptions?.length > 3;
  const slidesCount = useMemo(() => (screenType === ScreenType.Mobile ? 2 : 3), [screenType]);

  const [downloadLoanOptions, { isLoading }] = useDownloadLoanOptionsMutation();

  const onDownloadClick = useCallback(() => {
    downloadLoanOptions(getPDFLoanOptions(loanOptions, loanInfo));
  }, [downloadLoanOptions, loanOptions, loanInfo]);

  const onEmailClick = useCallback(() => {
    NiceModal.show(SendLoanProgramModal, { loanOptions, loanInfo });
  }, [loanInfo, loanOptions]);

  if (isEmpty(loanOptions)) {
    return (
      <EmptyStateText component={TypographyComponent.H7} weight={TypographyWeight.Regular}>
        Sorry, we don&apos;t have any loan options that meet this criteria. Please make some
        adjustments and try again
      </EmptyStateText>
    );
  }

  return (
    <>
      <RateComparisonTitle component={TypographyComponent.H6} weight={TypographyWeight.Bold}>
        Programs to compare
      </RateComparisonTitle>
      {isShowHint && (
        <Typography component={TypographyComponent.H8}>
          Scroll left to view more loan terms
        </Typography>
      )}

      <RateComparisonCarousel isAlwaysWithArrows visibleSlidesCount={slidesCount}>
        {loanOptions.map(option => (
          <ProgramCard isMobileView={isMobileView} key={option.loanOptionId} loanOption={option} />
        ))}
      </RateComparisonCarousel>

      <ButtonsContainer>
        <Button data-testid="rate_comparison_email_button" onClick={onEmailClick}>
          Email
        </Button>
        <DownloadPDFButton
          data-testid="rate_comparison_download_pdf_button"
          onClick={onDownloadClick}
        >
          {isLoading ? (
            <CircleSpinner color={theme.colors.primary} size={22} />
          ) : (
            <DownloadPDFIcon />
          )}
        </DownloadPDFButton>
      </ButtonsContainer>
    </>
  );
};

export default RateComparison;
