import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Formik } from 'formik';

import { ButtonVariant, TypographyComponent } from 'enums/ui';
import { getApiError } from 'utils/api';
import { getPDFLoanOptions } from 'utils/loanOptions';

import { useSendLoanOptionsByEmailMutation } from 'services/baseAPI';
import { selectCompanyAndRealtorId } from 'store/selectors/auth';
import {
  LoanOption,
  PotentialPurchaseLoanInfo,
  PotentialRefinanceLoanInfo,
} from 'types/application';

import EmailField from 'components/@fields/EmailField';
import NameField from 'components/@fields/NameField';
import Button from 'components/Button';
import CircleSpinner from 'components/CircleSpinner';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { Typography } from 'components/Typography';

import { ButtonsContainer, Form, SendLoanProgramContainer } from './SendLoanProgramsModal.styles';
import schema from './SendLoanProgramsModal.validationSchema';

interface Props {
  loanOptions: LoanOption[];
  loanInfo: PotentialPurchaseLoanInfo | PotentialRefinanceLoanInfo;
}

const initialValues = {
  firstName: '',
  lastName: '',
  emailAddress: '',
};

export default NiceModal.create<Props>(({ loanOptions, loanInfo }) => {
  const modal = useModal();

  const { companyId, realtorId } = useSelector(selectCompanyAndRealtorId);

  const [sendLoanOptions, { error }] = useSendLoanOptionsByEmailMutation();
  const APIError = getApiError(error);

  const onSubmit = useCallback(
    async (values: typeof initialValues) => {
      await sendLoanOptions({
        ...getPDFLoanOptions(loanOptions, loanInfo),
        companyId,
        realtorId,
        ...values,
      }).unwrap();

      modal.remove();
      toast('Email was sent');
    },
    [companyId, loanInfo, loanOptions, modal, realtorId, sendLoanOptions],
  );

  return (
    <SendLoanProgramContainer withCloseIcon={false}>
      <Typography component={TypographyComponent.H5}>Send Loan Programs to Client</Typography>

      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
        {({ handleChange, isSubmitting, dirty }) => (
          <Form>
            <NameField
              isAbsoluteError
              required
              label="First Name"
              name="firstName"
              onChange={handleChange}
            />
            <NameField
              isAbsoluteError
              required
              label="Last Name"
              name="lastName"
              onChange={handleChange}
            />
            <EmailField
              isAbsoluteError
              required
              label="Email"
              name="emailAddress"
              onChange={handleChange}
            />

            {APIError && <ErrorMessage isCenterAlign error={APIError} marginBottom={15} />}
            <ButtonsContainer>
              <Button $minWidth={120} disabled={!dirty} type="submit">
                {isSubmitting ? <CircleSpinner size={16} /> : 'Send Email'}
              </Button>
              <Button type="submit" variant={ButtonVariant.Transparent} onClick={modal.remove}>
                Cancel
              </Button>
            </ButtonsContainer>
          </Form>
        )}
      </Formik>
    </SendLoanProgramContainer>
  );
});
