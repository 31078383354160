import { PROPERTY_TYPE_TO_LABEL_MAP_FOR_PDF } from 'constants/application';
import { STATE_ABBR_TO_NAME_MAP } from 'constants/states';
import { LoanPurpose } from 'enums/application';
import { formatCurrency } from 'utils/formatting';

import { LoanOptionsPayloadForPDF } from 'services/baseAPI/types';
import {
  LoanOption,
  LoanOptionForPDF,
  PotentialPurchaseLoanInfo,
  PotentialRefinanceLoanInfo,
} from 'types/application';

const createLoanOptionStrings = (loanOptions: LoanOptionForPDF[]): string[] =>
  loanOptions.map(
    ({ term, rate, apr, points, fees, payment, cost3Years, cost5Years, cost8Years }) => `
      <div class='loan_option_card'>
        <div class='loan_option_card_term_wrapper'>
          <span class='loan_option_card_term'>${term}</span>
          <span class='loan_option_card_term bordered_term'>${term}</span>
        </div>
        <p class='loan_option_term_text'>Years</p>
        <div class='separate_line'></div>

        <p class='loan_option_card_title'>Rate</p>

        <div class='pink_container loan_option_value_container'>
          ${rate}%
        </div>

        <div class='separate_line'></div>
        <p class='loan_option_card_title'>APR</p>

        <div class='pink_container loan_option_value_container'>
          ${apr}%
        </div>

        <div class='separate_line'></div>
        <p class='loan_option_card_title'>Fees</p>

        <div class='pink_container loan_option_value_container'>
          ${fees}
        </div>

        <div class='fees_additional_data first'><span class='bold'>3yr cost:</span> ${cost3Years}</div>
        <div class='fees_additional_data'><span class='bold'>5yr cost:</span> ${cost5Years}</div>
        <div class='fees_additional_data'><span class='bold'>8yr cost:</span> ${cost8Years}</div>

        <div class='separate_line'></div>
        <p class='loan_option_card_title'>Points</p>

        <div class='pink_container loan_option_value_container'>
          ${points}
        </div>

        <div class='separate_line'></div>
        <p class='loan_option_card_title'>Payment/MO</p>

        <div class='pink_container loan_option_value_container'>
          ${payment}
        </div>
      </div>
  `,
  );

const getRelevantLoanOptions = (loanOptions: LoanOption[]): LoanOptionForPDF[] => {
  // we need only 3 most beneficial options from 3 different longest periods
  const relevantOptionsMap = loanOptions.reduce(
    (acc, option) => {
      const { loanPeriod } = option;

      if (acc[loanPeriod]) {
        if (option.paymentMonth < acc[loanPeriod].paymentMonth) {
          acc[loanPeriod] = option;
        }
      } else if (Object.keys(acc).length < 3) {
        acc[loanPeriod] = option;
      }

      return acc;
    },
    {} as { [key: string]: LoanOption },
  );

  return Object.values(relevantOptionsMap)
    .map(
      ({ apr, fees, rate, points, paymentMonth, cost3Year, cost5Year, cost8Year, loanPeriod }) => ({
        term: loanPeriod / 12,
        rate,
        apr,
        points,
        fees: formatCurrency(fees),
        payment: formatCurrency(paymentMonth),
        cost3Years: formatCurrency(cost3Year),
        cost5Years: formatCurrency(cost5Year),
        cost8Years: formatCurrency(cost8Year),
      }),
    )
    .sort((a, b) => b.term - a.term); // sort must be applied as Object doesn't guarantee order
};

export const getPDFLoanOptions = (
  loanOptions: LoanOption[], // Important: we receive already sorted (DSC) loan options by loanPeriod
  loanInfo: PotentialPurchaseLoanInfo | PotentialRefinanceLoanInfo,
): LoanOptionsPayloadForPDF => {
  const relevantOptions = getRelevantLoanOptions(loanOptions);
  const {
    city,
    state,
    propertyType,
    propertyValue,
    downPaymentValue,
    firstMortgageBalance,
    loanPurpose,
  } = loanInfo;

  const isPurchase = loanPurpose === LoanPurpose.Purchase;
  const lastPart = isPurchase
    ? `a <span>${Math.round((+downPaymentValue / +propertyValue) * 100)}%</span> down payment`
    : `<span>${formatCurrency(+firstMortgageBalance)}</span> mortgage left`;

  const message = `
    <span>
      Here is a cost analysis for your
      <span>${PROPERTY_TYPE_TO_LABEL_MAP_FOR_PDF[propertyType]}</span>
      ${isPurchase ? 'purchase' : 'refinance'} in
      <span>${city}, ${STATE_ABBR_TO_NAME_MAP[state]}</span>
      for <span>${formatCurrency(+propertyValue)}</span>
      with ${lastPart}.
    </span>
  `;

  return {
    message,
    timestamp: Date.now(),
    loanOptions: createLoanOptionStrings(relevantOptions),
  };
};
