import { FC, MouseEvent, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useModal } from '@ebay/nice-modal-react';

import { CARD_LABELS, FEE_DETAILS, TOOLTIP_CONTENT_MAP, YEARS_DATA } from 'constants/programCard';
import { TypographyComponent } from 'enums/ui';
import { formatCurrency } from 'utils/formatting';
import { getTooltipId } from 'utils/id';

import { selectIsDesktopView } from 'store/selectors/ui';
import { LoanOption } from 'types/application';

import RateComparisonInfo from 'components/@modals/RateComparisonInfo';
import { Tooltip } from 'components/@styled';
import { UpfrontCosts } from 'components/ProgramCard';

import RateSection from '../RateSection';

import { Header, Program, Separator, Term, Years, YearsLabel } from './ProgramCard.styles';

interface ProgramCardProps {
  loanOption: LoanOption;
  isMobileView: boolean;
}

const ProgramCard: FC<ProgramCardProps> = ({ loanOption, isMobileView }) => {
  const isDesktop = useSelector(selectIsDesktopView);

  const rateComparisonModal = useModal(RateComparisonInfo);

  const {
    rate,
    apr,
    fees,
    feeDetails,
    cost3Year,
    cost5Year,
    cost8Year,
    paymentMonth,
    loanPeriod,
    points,
  } = loanOption;

  const onOpenModal = useCallback(
    (event: MouseEvent) => {
      const { tooltipId } = (event.currentTarget as HTMLElement)?.dataset || {};
      if (tooltipId) {
        rateComparisonModal.show({
          ...(tooltipId.includes(getTooltipId(FEE_DETAILS))
            ? { Component: <UpfrontCosts isModal feeDetails={feeDetails} /> }
            : { text: TOOLTIP_CONTENT_MAP[tooltipId] }),
        });
      }
    },
    [feeDetails, rateComparisonModal],
  );

  const yearsCost = useMemo(() => {
    const priceToIndexMap = {
      0: cost3Year,
      1: cost5Year,
      2: cost8Year,
    } as { [key: number]: number };

    return YEARS_DATA.map(({ id, label }, i) => (
      <>
        <YearsLabel
          component={TypographyComponent.H7}
          data-tooltip-id={id}
          onClick={isDesktop ? undefined : onOpenModal}
        >
          {label}:
        </YearsLabel>
        {isMobileView && <br />} {formatCurrency(priceToIndexMap[i])}
        {isDesktop && <Tooltip content={TOOLTIP_CONTENT_MAP[id]} id={id} variant="light" />}
      </>
    ));
  }, [cost3Year, cost5Year, cost8Year, isDesktop, isMobileView, onOpenModal]);

  return (
    <Program data-testid="program_card">
      <Header data-testid="program_card_header">
        <Term data-testid={`program_card_term_${loanPeriod / 12}`}>{loanPeriod / 12}</Term>
        <Years component={TypographyComponent.H6}>Years</Years>
      </Header>

      <Separator $hasChildren={false} />

      <RateSection
        extraInfo={`${CARD_LABELS.points}: ${points}`}
        label={CARD_LABELS.rate}
        value={`${rate}%`}
        onOpenModal={onOpenModal}
      />
      <RateSection label={CARD_LABELS.apr} value={`${apr}%`} onOpenModal={onOpenModal} />
      <RateSection
        extraInfo={yearsCost}
        feeDetails={feeDetails}
        label={CARD_LABELS.fees}
        value={formatCurrency(fees)}
        onOpenModal={onOpenModal}
      />
      <RateSection
        label={CARD_LABELS.paymentMo}
        value={formatCurrency(paymentMonth)}
        onOpenModal={onOpenModal}
      />

      {/*TODO: uncomment after adding a comparison table of competitors*/}
      {/*<ButtonWithIcon variant={ButtonVariant.Transparent}>*/}
      {/*  <ComparisonText component={TypographyComponent.H7}>Comparison</ComparisonText>*/}
      {/*  <Arrow />*/}
      {/*</ButtonWithIcon>*/}
    </Program>
  );
};

export default ProgramCard;
