import _ from 'lodash';

type FeatureFlagsType = {
  [key: string]: string;
};

// Use -FT postfix for all BE FeatureFlags
// New Feature Flags must have name like PascalCaseFeatureFlagName-00000-FT
export const FeatureFlags = Object.entries({
  RealtorsEnableTaskCompletion: 'RealtorsEnableTaskCompletion-23189-FT', // prev. realtorsEnableTaskCompletion23189Ft
  EnableSubmitTaskWithAddressFt: 'EnableSubmitTaskWithAddress-FT', // prev. enableSubmitTaskWithAddressFt

  // Pre-Approvals
  RealtorsEnablePreApprovalManagement: 'RealtorsEnablePreApprovalManagement-24441-FT', // prev. realtorsEnablePreApprovalManagement24441Ft

  // Documents
  ViewNewDocumentsTab: 'ViewNewDocumentsTabOnRealtors-35284-FT', // prev. viewNewDocumentsTabOnRealtors35284Ft

  // Loan/Application
  UseDynamicLoanProgress: 'UseDynamicLoanProgress-39264-FT', // prev. useDynamicLoanProgress39264Ft
  EnableLoanContactsOnPresentationRealtors: 'UseLoanContactsOnPresentationRealtors-48509-FT',
}).reduce((acc: FeatureFlagsType, [key, value]) => {
  acc[key] = _.camelCase(value);
  return acc;
}, {});

type DevFlagsType = {
  [key: string]: boolean;
};

export const DevFlags = Object.entries({
  DisablePreApprovalAutoDownload: 'DisablePreApprovalAutoDownload', // prev. disablePreApprovalAutoDownload
}).reduce((acc: FeatureFlagsType, [key, value]) => {
  acc[key] = _.camelCase(value);
  return acc;
}, {});

export const DEV_FLAGS = Object.values(DevFlags).reduce((acc, key) => {
  const valueFromLS = localStorage.getItem(key);
  acc[key] = valueFromLS ? valueFromLS === 'true' : false;
  return acc;
}, {} as DevFlagsType);
